div {
  box-sizing: border-box;
}

.flex-table {
  display: grid;
  grid-template-columns: [flag] 10% [name] 40% [location] 35% [action] 15%;
  grid-auto-rows: minmax(56px, auto);
  transition: 0.5s;
}

.flex-column {
  display: block;
  width: 100%;
  text-align: center;
  padding: 0.5em 0.5em;
}

.details-grid {
  display: grid;
  grid-template-columns: [address] 30% [description] 50% [contact] 20%;
}

.detail-address {
  grid-area: address;
}

.detail-description {
  grid-area: description;
}

.detail-contact {
  grid-area: contact;
  background: #fafafa;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
}

@media all and (min-width: 700px) and (max-width: 899px) {
  .details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'address contact'
      'description description';
  }

  .detail-contact {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .map-icon {
    display: none !important;;
  }
}

@media all and (max-width: 699px) {
  .flex-table {
    grid-template-columns: [flag] 84px [name] minmax(0, 1fr) [action] 15%;
  }

  .details-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'contact'
      'description'
      'address';
  }

  .detail-address {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .detail-contact {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .column-location {
    display: none;
  }

  .map-icon {
    display: none !important;
  }

  .column-name {
    padding-left: 15px !important;
  }
}

.bt-toast .toast-crunch {
  box-shadow: #21201e 0 3px 15px !important;
}

svg {
  display: inline-block;
  vertical-align: middle;
}
